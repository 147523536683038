<template>
  <div class="layout-footer">
    <span class="footer-text" style="margin-right: 5px"><strong>Copyright &copy; {{year}} {{(year === currentYear) ? null : '-' + currentYear}} Five2one.</strong> All rights reserved.</span>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      year: 2024,
    }
  }
}
</script>

<style scoped>
</style>