<template>
	<div class="layout-menu-container">
		<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" :key="menuKey"/>
	</div>
</template>

<script>
import AppSubmenu from './Submenu';

export default {
	props: {
		model: Array
	},
	data() {
		return {
			menuKey: 0
		}
	},
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        }
    },
	components: {
		'AppSubmenu': AppSubmenu
	},
	watch: {
		$route() {
			setTimeout(() => {
				if (this.$router.currentRoute.path == "/admin/403") {
					this.menuKey++;
				}
			}, 100)
		},
	},
}
</script>

<style scoped>

</style>