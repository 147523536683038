<template>
	<ul v-if="items">
		<template>
			<li v-for="(item,i) of items" :key="i" :class="[{'active-menuitem': (activeIndex === i && !item.to)|| routeArr === item.to}]">
				<div v-if="item.items && root===true" class='arrow'></div>
				<a class="sub-menu-link" :id="item.id" v-if="item.to" :href="item.to" :class="[{'active-route': activeIndex === i || routeArr === item.to}]" @click="onMenuItemClick($event,item,i)" :target="item.target" exact>
					<i :class="`${(item.icon || 'fa fa-circle-o')} mr-2`"></i>
					<span class="custom-sub">{{item.label}}</span>
					<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
					<span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
				</a>
				<a v-if="!item.to" :href="item.url||'#'" @click="onMenuItemClick($event,item,i)" target="item.target">
					<i :class="`${(item.icon || 'fa fa-circle-o')} mr-2`"></i>
					<span>{{item.label}}</span>
					<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
					<span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
				</a>
				<transition name="layout-submenu-wrapper">
					<AppSubmenu v-show="activeIndex === i" :items="item.items" @menuitem-click="$emit('menuitem-click', $event)"></AppSubmenu>
				</transition>
			</li>
		</template>
	</ul>
</template>
<script>
export default {
	name: 'AppSubmenu',
	props: {
		items: Array,
		root: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeIndex : null,
			currentPath: this.$router.currentRoute.path,
			// routeAdd: this.$router.currentRoute.path.split('/add'),
			// routeView: this.$router.currentRoute.path.split('/view'),
			// routeEdit: this.$router.currentRoute.path.split('/edit'),
			myInterval: setInterval(this.loadActiveMenu, 100),
			routeArr: this.$router.currentRoute.path,
		}
	},
	watch: {
		async $route() {
			let path = this.$router.currentRoute.path;
			let routeArr = path.split('/');
			let routeArr_tmp = [routeArr[0],routeArr[1],routeArr[2]];
			this.routeArr = routeArr_tmp.join("/");
			setTimeout(async () => {
				this.loadActiveMenu(false);
			}, 100)
		},
		"routeArr"() {
			this.loadActiveMenu();
		}
	},
	mounted() {
		let path = this.$router.currentRoute.path;
		let routeArr = path.split('/');
		let routeArr_tmp = [routeArr[0],routeArr[1],routeArr[2]];
		this.routeArr = routeArr_tmp.join("/");
	},
	// created() {
	// 	let path = this.$router.currentRoute.path;
	// 	let routeArr = path.split('/');
	// 	let routeArr_tmp = [routeArr[0],routeArr[1],routeArr[2]];
	// 	this.routeArr = routeArr_tmp.join("/");
	// },
	methods: {
		async loadActiveMenu(activeMenu=true) {
			var activeRouteNewArr = [];
			this.$nextTick(async () => {
				this.$nextTick( async () => {
					var activeRoute = document.querySelectorAll('.active-route');
					for (let i=0; i < activeRoute.length; i++) {
						var activeRouteNew = activeRoute[i];
						// if (this.routeArr.includes(activeRouteNew.getAttribute("href"))) {
						if (this.routeArr === activeRouteNew.getAttribute("href")) {
							if (activeRouteNew && activeMenu) {
								var parentRoute = activeRouteNew.closest('ul');
								if (parentRoute && parentRoute.style.display == "none") {
									var grandParentRoute = parentRoute.closest('li');
									if (grandParentRoute) {
										var childElement = grandParentRoute.children;
										if (!this.$commonFuction.isEmpty(childElement)) {
											clearInterval(this.myInterval);
											parentRoute.click();
											for (let c = 0; c < childElement.length; c++) {
												await childElement[c].click();
											}
										}
									}
								}
							}
						} else {
							activeRouteNewArr.push(activeRouteNew);
						}
					}
					activeRouteNewArr.map(m => {
						m.classList.remove("active-route");
					})
				})
			});
		},
		onMenuItemClick(event, item, index) {
			if (item.disabled || this.routeArr == item.to) {
				event.preventDefault();
				return;
            }
            
            if (!item.to && !item.url) {
                event.preventDefault();
            }

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
			}

			this.activeIndex = index === this.activeIndex ? null : index;
			this.currentPath = null;
			this.routeArr = [];
			//chặn href load thêm lần nữa
			event.preventDefault();
			this.$emit('menuitem-click', {
				originalEvent: event,
				item: item
			});
		}
	},
	components: {
		"AppSubmenu": this
	}
}
</script>

<style scoped>
	.custom-sub{
		/* font-size: 16px; */
	}
	.sub-menu-link {
		display: flex;
		align-items: center;
	}
</style>