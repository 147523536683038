
<template>
  <div id="menuTopBar" class="layout-topbar">
    <button id="toggle-sidebar" class="p-link layout-menu-button" @click="onMenuToggle">
      <span style="font-size: 24px;" class="pi pi-bars"></span>
    </button>
    <div id="topbarIcon" class="layout-topbar-icons">
      <!-- <button id="btnNoti" class="p-link">
        <span class="layout-topbar-item-text">Notification</span>
        <span style="font-size: 24px;" class="layout-topbar-icon fa fa-bell"></span>
      </button>
      
      <button id="btnProfile" class="p-link">
        <span class="layout-topbar-item-text">Setting</span>
        <span style="font-size: 24px;" class="layout-topbar-icon fa fa-gear"></span>
      </button> -->

      <button id="btnProfile" class="p-link" @click="OnShow">
        <span class="layout-topbar-item-text">User</span>
        <span style="font-size: 24px;" class="layout-topbar-icon fa fa-user"></span>
      </button>
      <OverlayPanel ref="op" appendTo="btnProfile" id="overlay_panel" style="width:150px">
        <div>
          <a href="#" title="Change Password" @click='openChangePassword()'>
            <div class="fontsize">Change Password</div>
          </a>
        </div>
        <hr class="new1" />
        <div>
          <a href="#" @click='submitLogout' class="fontsize" title="Logout">Logout</a>
        </div>
      </OverlayPanel>
    </div>
    <Dialog header="Sign out" :visible.sync="displayLogout" :style="{width: '350px','text-align': 'center'}" :modal="true" :position="position">
      <div class="confirmation-content">
        <span>Are you sure, you want to sign out?</span>
      </div>
      <template #footer>
        <div class="custom-center">
          <!--          <Button label="Cancel" @click="closeLogout()" class="p-button-text p-button-danger default-custom-btn" />-->
          <!--          <Button label="Confirm" @click="validateLogout()" class="p-button-text default-custom-btn" autofocus="autofocus" />-->

          <Button label="Cancel"  @click="closeLogout()" icon="pi pi-times" class="p-button-warning" />
          <Button label="Confirm" @click="validateLogout()" icon="pi pi-check" class="p-button-danger  btn-confirm-delete-dialog" />
        </div>
      </template>
    </Dialog>

    <Dialog header="Change password" :visible.sync="displayChangePassword" :style="{width: '400px'}" :modal="true" :position="position">
      <div class="confirmation-content">
        <span class="" style="color:red" v-if='error'> {{error}}</span>
        <form action="#" autocomplete="off">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group custom-form-group" style="position: relative">
                <label class="control-label">Current password</label>
                <input id="passwordOld" v-model="passwordOld" :type='passwordType' required="required" class="form-control form-custom-pass custom-pass-input" style="padding-right: 40px !important;margin-bottom: 15px;">
                <i style="margin-top: -45px; cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibility" v-if="passwordType === 'text'">visibility</i>
                <i style="margin-top: -45px; cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibility" v-else>visibility_off</i>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group custom-form-group" style="position: relative;">
                <label class="control-label">New password</label>
                <input id="passwordNew" v-model="passwordNew" :type='secondPasswordType' @keyup='checkPassword' required="required" class="form-control form-custom-pass custom-pass-input" style="padding-right: 40px !important;margin-bottom: 15px;">
                <i style="margin-top: -45px; cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibilityNew" v-if="secondPasswordType === 'text'">visibility</i>
                <i style="margin-top: -45px; cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibilityNew" v-else>visibility_off</i>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group custom-form-group" style="position: relative;">
                <label class="control-label">Re-enter password</label>
                <input id="passwordConfirm" v-model="passwordConfirm" :type='confirmPasswordType' @keyup='checkPassword' required="required" class="form-control form-custom-pass custom-pass-input" style="padding-right: 40px !important;">
                <i style="cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibilityConfirm" v-if="confirmPasswordType === 'text'">visibility</i>
                <i style="cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibilityConfirm" v-else>visibility_off</i>
              </div>
            </div>
          </div>
        </form>
      </div>
      <template #footer>
        <div class="custom-center">

          <Button label="Cancel"  @click="closeChangePassword()" icon="pi pi-times" class="p-button-warning" />
          <Button label="Confirm" @click="submitChangePassword()" icon="pi pi-check" class="p-button-danger  btn-confirm-delete-dialog" />
          <!--          <Button label="Cancel" @click="closeChangePassword()" class="p-button-text p-button-danger default-custom-btn" />-->
          <!--          <Button label="Save" @click="submitChangePassword()" class="p-button-text default-custom-btn" autofocus />-->
        </div>
      </template>
    </Dialog>


  </div>
</template>

<script>
import ApiRepository from "@/core/ApiRepository";
//import store from '../../../store'
//import firebase from 'firebase'
import Cryptojs from 'crypto-js'
export default {
  data() {
    return {
      show: false,
      displayLogout: false,
      passwordOld: null,
      passwordNew: null,
      passwordConfirm: null,
      passwordType: 'password',
      secondPasswordType: 'password',
      confirmPasswordType: 'password',
      displayChangePassword: false,
      error: null,
      position : 'top'
    }
  },
  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    OnShow(event) {
      this.$refs.op.toggle(event);
    },
    submitLogout() {
      this.displayLogout = true;
      // this.$modal.show(mdLogout, {}, {
      //   clickToClose: false,
      //   height: 'auto',
      //   adaptive: true,
      //   classes: ['col-md-6'],
      //   reset: true
      // })
    },
    closeLogout() {
      this.displayLogout = false;
    },
    closeChangePassword() {
      this.displayChangePassword = false;
    },
    openChangePassword() {
      // close pop-up edit profile
      //this.$emit('close')
      this.error = null;
      this.passwordOld = null;
      this.passwordNew = null;
      this.passwordConfirm = null;
      this.displayChangePassword = true;
      try {
        this.displayChangePassword = true;
      } catch (e) {
        console.error(e)
      }
    },
    validateLogout() {
      // console.log('Logout')
      this.$AuthenticationApp.clearDataLogout().then(() => {
        //this.$emit('close')
        this.displayLogout = false;
        //this.$router.push('/auth/login')
      })
    },
    switchVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    switchVisibilityNew() {
      this.secondPasswordType = this.secondPasswordType === 'password' ? 'text' : 'password'
    },
    switchVisibilityConfirm() {
      this.confirmPasswordType = this.confirmPasswordType === 'password' ? 'text' : 'password'
    },
    checkPassword() {
      // console.log(e)
      if (this.passwordConfirm !== this.passwordNew) {
        this.matched = false
      } else {
        this.matched = true
      }
    },
    async submitChangePassword() {
      var that = this;
      if (!this.$commonFuction.isEmpty(this.passwordConfirm)
          && !this.$commonFuction.isEmpty(this.passwordOld)
          && !this.$commonFuction.isEmpty(this.passwordNew)
      ) {
        if (this.matched == false){
          this.error = 'New password does not match.';
          return false;
        }
        //console.log("await this.getStateLogin()",(await this.$AuthenticationApp.getToken()))
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        that.$commonFuction.is_loading(true);
        var dataChangePassword = {
          password_current: (Cryptojs.SHA256(this.passwordOld.toString())).toString() ,
          password_new: (Cryptojs.SHA256(this.passwordNew.toString())).toString() ,
        }
        ApiRepository.post(this.$constants.API.CHANGE_PASSWORD, dataChangePassword, {headers}).then(async (res) => {
          console.log('=========> createUser: ', res)
          that.$toast.add({severity: 'success', summary: 'Success', detail: 'Change password successfully', life: 3000});
          that.$commonFuction.is_loading(false);
          that.displayChangePassword = false;
          //logout ra
          that.$AuthenticationApp.clearDataLogout().then(() => {
            this.$router.push('/auth/login')
          })
        })
            .catch(async error => {
              //this.errorMessage = error.message;
              var message = error.response.data.message;
              //console.error("There was an error!", message);
              switch(message) {
                case that.$constants.MSG_ERROR.INVALID_TOKEN_ERR:
                  that.$toast.add({
                    severity: 'error',
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.SESION_LOGIN_EXPIRED,
                    life: 3000
                  });
                  await that.$commonFuction.sleep(2000);
                  //logout
                  that.$AuthenticationApp.clearDataLogout().then(() => {})
                  that.$commonFuction.is_loading(false);
                  break;
                case that.$constants.MSG_ERROR.MISMATCH_PARAMS_ERR:
                  that.$toast.add({
                    severity: 'error',
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                    life: 3000
                  });
                  that.$commonFuction.is_loading(false);
                  break;
                case that.$constants.MSG_ERROR.INVALID_CURRENT_PASSWORD_ERR:
                  that.$toast.add({
                    severity: 'error',
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.INVALID_CURRENT_PASSWORD_ERR,
                    life: 3000
                  });
                  that.$commonFuction.is_loading(false);
                  break;
                case that.$constants.MSG_ERROR.PASSWORD_NEW_NOT_SAME_PASSWORD_CURRENT_ERR:
                  that.$toast.add({
                    severity: 'error',
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.PASSWORD_NEW_NOT_SAME_PASSWORD_CURRENT_ERR,
                    life: 3000
                  });
                  that.$commonFuction.is_loading(false);
                  break;
                default:
                  that.$toast.add({
                    severity: 'error',
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                    life: 3000
                  });
                  that.$commonFuction.is_loading(false);
              }
            });
      } else  {
        // console.log(this.$refs)
        this.error = 'Please enter full information.'
      }
    }
  },
}
</script>
<style>
.fontsize {
  font-size: 13px;
}
#overlay_panel {
  top: 15px !important;
  left: unset !important;
  right: 0 !important;
}
.form-custom-pass{
  padding-right: 40px !important;
  padding-right: 42.5px;
  border-radius: 3px !important;
  box-shadow: none;
  border-color: #d2d6de;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  font-family: inherit;
}
.lg-icon-password {
  margin-top: -30px;
  display: block;
  position: absolute;
  right: 10px;
}
.custom-pass-input{
  margin-top: 2px;
}
.default-custom-btn{
  width: 125px !important;
}
.custom-center{
  //text-align: center;
  text-align: right;
}
</style>